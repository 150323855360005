<template>
	<div class="LubokSearchView view" v-if="viewIsReady">
		<LuMobileHeader
			class="app__mobileNav"
			v-if="$root.isSmallDevice"
		></LuMobileHeader>

		<div class="grid grid--pageTemplateLinear vSpace vSpace--page hSpace hSpace--page maxWidth maxWidth--page">
			<!-- header: page title with search query -->
			<div>
				<LuPageHeader
					:title="$t('title.searchResultsFor') + '<br />»' + searchString + '«'"
				></LuPageHeader>
			</div>
			<!-- content: results in shop+pages+events -->
			<div>
				<!-- content: results in shop -->
				<h2 class="font font--sizeDefault border border--top border--strong vSpace vSpace--pageHeaderTitle">
					<strong v-html="$t('title.searchResultsInShop')"></strong><br />
					<br />
				</h2>
				<div class="border border--top border--bottom">
					<!-- Loading spinner -->
					<div class="flex flex--row flex--middle flex--left vSpace vSpace--pageHeaderTitle"
						 v-if="_.isUndefined(products)">
						<div class="font" :class="{ 'font--sizeBig' : !$root.isSmallDevice, 'font--sizeMedium' : $root.isSmallDevice }">
							<Spinner></Spinner><br />
						</div>
					</div>
					<!-- Nothing found message -->
					<div class="flex flex--row flex--middle flex--left font font--sizeBig vSpace vSpace--pageHeaderTitle"
						 v-if="_.isArray(products) && !products.length">
						<span v-html="$t('message.noSearchResults')"></span><br />
					</div>
					<!-- Search results -->
					<ul class="reset reset--list" v-show-slide="_.isArray(products) && products.length">
						<li class="border"
							:class="{'border--top' : index}"
							v-for="(post, index) in products"
							:key="post.id">
							<h2 class="font font--bold vSpace vSpace--pageHeaderTitle"
								:class="{ 'font--sizeBig' : !$root.isSmallDevice, 'font--sizeMedium' : $root.isSmallDevice }">
								<router-link class="block link link--withArrow" :to="$root.getPathLinkInCurrentLang( post.link )">
									<span v-if="post" v-html="$root.getTranslatedAcfValue(post.acf, 'author')"></span> –
									<span v-if="post" v-html="$root.getTranslatedAcfValue(post.acf, 'postTitle')"></span>
									&nbsp;
									<!--
									-->
								</router-link>
							</h2>
						</li>
					</ul>
				</div>
				<br /><br /><br />
				<!-- content: results in pages -->
				<h2 class="font font--sizeDefault border border--top border--strong vSpace vSpace--pageHeaderTitle">
					<strong v-html="$t('title.searchResultsInPages')"></strong><br />
					<br />
				</h2>
				<div class="border border--top border--bottom">
					<!-- Loading spinner -->
					<div class="flex flex--row flex--middle flex--left vSpace vSpace--pageHeaderTitle"
						 v-if="_.isUndefined(pages)">
						 <div class="font" :class="{ 'font--sizeBig' : !$root.isSmallDevice, 'font--sizeMedium' : $root.isSmallDevice }">
 							<Spinner></Spinner><br />
 						</div>
					</div>
					<!-- Nothing found message -->
					<div class="flex flex--row flex--middle flex--left font font--sizeBig vSpace vSpace--pageHeaderTitle"
						 v-if="_.isArray(pages) && !pages.length">
						<span v-html="$t('message.noSearchResults')"></span><br />
					</div>
					<!-- Search results -->
					<ul class="reset reset--list" v-show-slide="_.isArray(pages) && pages.length">
						<li class="border"
							:class="{'border--top' : index}"
							v-for="(post, index) in pages"
							:key="post.id">
							<h2 class="font font--bold vSpace vSpace--pageHeaderTitle"
								:class="{ 'font--sizeBig' : !$root.isSmallDevice, 'font--sizeMedium' : $root.isSmallDevice }">
								<router-link class="block link link--withArrow" :to="$root.getPathLinkInCurrentLang( post.link )">
									<span v-if="post" v-html="$root.getTranslatedAcfValue(post.acf, 'postTitle')"></span>
									&nbsp;
								</router-link>
							</h2>
						</li>
					</ul>
				</div>
				<br /><br /><br />
				<!-- content: results in events -->
				<h2 class="font font--sizeDefault border border--top border--strong vSpace vSpace--pageHeaderTitle">
					<strong v-html="$t('title.searchResultsInEvents')"></strong><br />
					<br />
				</h2>
				<div class="border border--top border--bottom">
					<!-- Loading spinner -->
					<div class="flex flex--row flex--middle flex--left vSpace vSpace--pageHeaderTitle"
						 v-if="_.isUndefined(events)">
						 <div class="font" :class="{ 'font--sizeBig' : !$root.isSmallDevice, 'font--sizeMedium' : $root.isSmallDevice }">
 							<Spinner></Spinner><br />
 						</div>
					</div>
					<!-- Nothing found message -->
					<div class="flex flex--row flex--middle flex--left font font--sizeBig vSpace vSpace--pageHeaderTitle"
						 v-if="_.isArray(events) && !events.length">
						<span v-html="$t('message.noSearchResults')"></span><br />
					</div>
					<!-- Search results -->
					<ul class="reset reset--list" v-show-slide="_.isArray(events) && events.length">
						<li class="border"
							:class="{'border--top' : index}"
							v-for="(post, index) in events"
							:key="post.id">
							<h2 class="font font--bold vSpace vSpace--pageHeaderTitle"
								:class="{ 'font--sizeBig' : !$root.isSmallDevice, 'font--sizeMedium' : $root.isSmallDevice }">
								<router-link class="block link link--withArrow" :to="$root.getPathLinkInCurrentLang( post.link )">
									<span v-if="post" v-html="$root.getTranslatedAcfValue(post.acf, 'postTitle')"></span>
									&nbsp;
								</router-link>
							</h2>
						</li>
					</ul>
				</div>
				<br /><br /><br />
			</div>
		</div>

		<!--
		<pre data-name="products">{{products}}</pre>
		<pre data-name="pages">{{pages}}</pre>
		<pre data-name="events">{{events}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import ViewMixin from './View.mixin.js'
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import Spinner from '@/components/Spinner.vue'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'
	import MhSmartHeader from '@/components/MhSmartHeader/MhSmartHeader.vue'

	import LuMobileHeader from '@/components/LuMobileHeader.vue'
	import LuPageHeader from '@/components/LuPageHeader.vue'

	export default {
		name: 'LubokSearchView',
		components: {
			Spinner,
			MhSmartHeader,
			MhDevGrid,
			LuMobileHeader,
			LuPageHeader,
		},
		mixins: [
			restHandler,
			ViewMixin,
		],
		props: {},
		data() {
			return {
				products : undefined,
				pages    : undefined,
				events   : undefined,
			}
		},
		watch: {},
		computed: {
			searchString() {
				return this.$route.params.searchString ? this.$route.params.searchString : ''
			},
		},
		methods: {
			doFetchProducts() {
				let validFields = []

				if( this.$root.currentLang == 'de' ) {
					validFields = [
						'postTitle__de',
						'author__de',
						'biblios__de',
						'text__de',
					]
				}
				if( this.$root.currentLang == 'en' ) {
					validFields = [
						'postTitle__en',
						'author__en',
						'biblios__en',
						'text__en',
					]
				}

				/*
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'product_post',
						perPage : -1,
						search : this.$route.params.searchString,
						searchSpecificACFOnly : validFields,
					},
					callback : (response) => {

						this.products      = response.data.result

						// send loaded to MhView to hide the spinner again
						//EventBus.$emit('stateChanged', 'loaded')
					},
				})
				*/

				// fetch products
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/lubok__products',
					params : {
						perPage : -1,
						search : this.$route.params.searchString,
						searchSpecificACFOnly : validFields,
					},
					callback : (response) => {
						this.products      = response.data.result
					},
				})
			},
			doFetchPages() {
				let validFields = []

				if( this.$root.currentLang == 'de' ) {
					validFields = [
						'postTitle__de',
						'contentModules__de/contentModules/*/text',
					]
				}
				if( this.$root.currentLang == 'en' ) {
					validFields = [
						'postTitle__en',
						'contentModules__en/contentModules/*/text',
					]
				}

				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'page',
						perPage : -1,
						search : this.$route.params.searchString,
						searchSpecificACFOnly : validFields,
					},
					callback : (response) => {
						/*
						this.totalPosts  = response.data.info.totalPosts
						this.totalPages  = response.data.info.totalPages
						this.currentPage = response.data.info.currentPage
						*/
						this.pages      = response.data.result

						// send loaded to MhView to hide the spinner again
						//EventBus.$emit('stateChanged', 'loaded')
					},
				})
			},
			doFetchEvents() {
				let validFields = []

				if( this.$root.currentLang == 'de' ) {
					validFields = [
						'postTitle__de',
						'location__de',
						'contentModules__de/contentModules/*/text',
					]
				}
				if( this.$root.currentLang == 'en' ) {
					validFields = [
						'postTitle__en',
						'location__en',
						'contentModules__en/contentModules/*/text',
					]
				}

				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'event_post',
						perPage : -1,
						search : this.$route.params.searchString,
						searchSpecificACFOnly : validFields,
					},
					callback : (response) => {
						/*
						this.totalPosts  = response.data.info.totalPosts
						this.totalPages  = response.data.info.totalPages
						this.currentPage = response.data.info.currentPage
						*/
						this.events      = response.data.result

						// send loaded to MhView to hide the spinner again
						//EventBus.$emit('stateChanged', 'loaded')
					},
				})
			},
		},
		created() {},
		mounted() {
			this.viewIsReady = true // this comes from View.mixin.js

			this.doFetchProducts()
			this.doFetchPages()
			this.doFetchEvents()

			this.title = this.$t('nav.search')
		},
	}
</script>

<style lang="less">
</style>
